// Open and close dropdowns
$(".dropdown-toggle" ).click(function() {
  let menu_change = $(this).data("dropdownToggle")
  $( `#${menu_change}` ).toggleClass( "hidden" )
  $(".dropdown-menu").not( $( `#${menu_change}` ) ).addClass("hidden")
});

$('body').click(function(evt){
  var target = $( event.target );
  if (!target.hasClass('dropdown-toggle')) {
    $(".dropdown-menu").addClass("hidden")
  }
});

// Open and close dropdowns in mobile
$(".dropdown-toggle-mobile" ).click(function() {
  let menu_change = $(this).data("collapseToggle")
  $( `#${menu_change}` ).toggleClass( "hidden" )
});

$(".collapse-menu" ).click(function() {
  let menu_change = $(this).data("collapseToggle")
  $( `#${menu_change}` ).toggleClass( "hidden" )
  $(".sub-menu-change").not( $( `#${menu_change}` ) ).addClass("hidden")
});

$(".mobile-list-toggle" ).click(function() {
  if ($(this).hasClass('current')){
    $(".current").toggleClass('current')
    $(this).parent().children('.sub-menu-change').addClass("hidden")
    $(this).children().children(".ham-sub-menu").toggleClass("hidden")
  } else {
    $(".current").parent().children('.sub-menu-change').addClass("hidden")
    $('.current').children().children('.ham-sub-menu').toggleClass('hidden')
    $(".current").toggleClass('current')
    $(this).parent().children('.sub-menu-change').removeClass("hidden")
    $(this).children().children(".ham-sub-menu").toggleClass("hidden")
    $(this).addClass('current')
  }
});

$(".toggle-menu" ).click(function() {
  $(this).parent().parent().children(".sub-menu-change").toggleClass( "hidden" )
});


// Search Input modal
$(".search-btn-toggle" ).click(function(e) {
  e.preventDefault();
  let modal = $("#search-modal")
  modal.toggleClass("hidden");

  if (!modal.hasClass("hidden")) {
    $("#search-page-query").focus();
    $("#search-page-query").val('');
  }
});
