import 'jquery/dist/jquery';
import 'expose-loader?$!jquery';
import 'expose-loader?jQuery!jquery';
import '../js/navbar';
import '../js/tailwind/faq';
import '../js/tailwind/issues';
import '../js/tailwind/letter';
import '../js/tailwind/search';
import '../js/tailwind/obituary';
import '../js/algolia/modal';
import '../js/algolia/search_result_page';
import '../js/sentry';
import '../js/jquery.maphilight';
import '../js/imageMapResizer';
import 'reading-time/src/readingTime';

require.context('../images', true);
import '../css/tailwind.scss';
import Rails from '@rails/ujs';
Rails.start();

$(document).ready(function () {
  $('body').readingTime({
    readingTimeAsNumber: false,
    wordsPerMinute: 275,
    wordCountTarget: '.words',
    lang: 'en',
  });
});

$(document).on('click', '.class-note-jump-links a', function (e) {
  e.preventDefault();
  var target = $(this).attr('href');
  $('html, body').animate(
    {
      scrollTop: $(target).offset().top,
    },
    1000
  );
  const header = document.querySelector('nav.header');
  header.classList.remove('slide-in');
  header.classList.add('slide-out');
});

// TODO: CHANGE THIS TO TAILWIND CONFIGURATION WHEN RAILS IS UPDATED AND
// WE STOP USING THE CDN, THERE IS AN ERROR ON SAFARI/IOS DEVICES AND
// PRODUCTION DOES NOT WORK WELL. ERASE COMMENT TO MAKE TAILWIND WORK

// tailwind.config = {
//   theme: {
//     fontSize: {
//       'm': ['14px'],
//       'l': ['16px'],
//       'xl': ['18px'],
//       '1xl': ['20px'],
//       '2xl': ['22px'],
//       '3xl': ['24px'],
//       '4xl': ['34px'],
//       '5xl': ['44px'],
//       'heading': ['55px'],
//     },
//     letterSpacing: {
//       'tight': '-0.5px',
//       'less-tight': '-0.3px',
//       'normal': '0.15px',
//       'wide': '0.3px',
//       'less-wide': '0.4px',
//       'wider': '0.5px'
//     },
//     extend: {
//       colors: {
//         's-bg-light-grey': '#F7F7F7',
//         's-grey': "#BDBDBD",
//         's-dark-grey': '#585754',
//         's-ligth-grey': '#888888',
//         's-bg-ligth-grey': '#F7F7F7',
//         's-bg-grey': '#2e2d29',
//         's-text-grey': '#E9E9E9',
//         's-dark-red': '#B1040E',
//         's-digital-red': '#E50808',
//         's-menu-red': "#7A0000",
//         's-light-black': '#181D1C',
//         's-red': '#F83535',
//         's-cardinal': '#541107',
//         's-cardinal-red': '#8C1515',
//         's-light-grey': '#d5d5d4',
//         's-black': '#171C1B',
//         's-fog-grey': '#F4F4F4',
//         's-sucess-green': '#2ecc71'
//       },
//       spacing: {
//         '3': '3px',
//         '5': '5px',
//         '8': '8px',
//         '10': '10px',
//         '15': '15px',
//         '20': '20px',
//         '25': '25px',
//         '30': '30px',
//         '40': '40px',
//         '50': '50px',
//         '60': '60px',
//         '75': '75px',
//         '100': '100px',
//         '100%': '100%',
//       },
//       padding: {
//         '5': '5px',
//         '10': '10px',
//         '15': '15px',
//         '16': '16px',
//         '20': '20px',
//         '25': '25px',
//         '32': '32px',
//         '30': '30px',
//         '50': '50px',
//         '100': '100px',
//       },
//       lineHeight: {
//         '16': '16px',
//         '17': '17px',
//         '23': '23px',
//         '25': '25px',
//         '27': '27px',
//         '28': '28px',
//         '44': '44px',
//         '55': '55px',
//       }
//     },
//     borderWidth: {
//         DEFAULT: '1px',
//         '0': '0',
//         '2': '2px',
//         '3': '3px',
//         '4': '4px',
//         '5': '5px',
//     },
//     backgroundPosition: {
//       'center-right': 'center right',
//     },
//     fontFamily: {
//       'sans-pro': ['Source Sans Pro', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'],
//       'serif-pro': ['Source Serif Pro', 'Georgia', 'Times', "Times New Roman", 'serif']
//     }
//   }
// }
