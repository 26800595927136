import * as Sentry from '@sentry/browser';

var RAILS_ENV = gon.rails_env;

if (RAILS_ENV === 'production' || RAILS_ENV === 'staging') {
  Sentry.init({
    dsn: gon.sentry_dsn,
    environment: RAILS_ENV
  });
}
