// FAQ
$(".open-faq-header" ).click(function() {
  let menu_change = $(this).data("faqToggle")

  $(this).addClass("opened")
  $(".faq-header").not( $(this) ).removeClass("opened")
  $('.faq-text').addClass("hidden")

  $(".faq-toggle").not( $( `#${menu_change}` ) ).addClass("hidden")
  $( `#${menu_change}` ).toggleClass( "hidden" )
});

$(".faq-wrapper" ).click(function() {
  $('.faq-text').not($(this).children('.faq-text')).addClass("hidden")
  $('.angle-arrow.fa-angle-up').not($(this).children('.angle-arrow')).addClass("hidden")
  $('.angle-arrow.fa-angle-down').not($(this).children('.angle-arrow')).removeClass("hidden")
  $(this).children('.faq-text').toggleClass("hidden")
  $(this).children('.angle-arrow').toggleClass("hidden")
});


$( document ).ready(function() {
  let searchParams = new URLSearchParams(window.location.search)
  if (searchParams.has('anchor')){
    let param = searchParams.get('anchor')
    $(".faq-header").removeClass("opened")
    $('#submission-menu').addClass("opened")
    $(".faq-toggle").addClass("hidden")
    $('#submissions').toggleClass( "hidden" )
    $( `#${param}` ).children('.faq-text').toggleClass('hidden')
  }
});
