// Letter character count
$(document).ready(function() {
  $(".letter-message").keyup(function(){
    var len = this.value.length;
    const max_len = 1500
    if (len >= max_len) {
      this.value = this.value.substring(0, max_len);
    } else {
      $('.message-count').text(`${len}/${max_len}`);
    }
  });
});
