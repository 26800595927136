import * as algoliasearch from 'algoliasearch/lite';
import * as instantsearch from 'instantsearch.js/dist/instantsearch.production.min'
import * as autocomplete from 'autocomplete.js/dist/autocomplete';

$( document ).ready(function() {
  const client = algoliasearch(gon.algolia_app_id, gon.algolia_search_only_api_key);
  const indexes = ['Issue', 'Collection', 'Section', 'Content', 'Author', 'Category', 'About'];
  const search = indexes.map(function (i) {
    return buildTemplate(i);
  });

  function findValuesByIndex(index) {
    const indexes = {
      Issue: { title: 'Issues', resource: 'issues/' },
      Collection: { title: 'Collections', resource: 'collections/' },
      Section: { title: 'Sections', resource: 'sections/' },
      Content: { title: 'Contents', resource: 'contents/' },
      Author: { title: 'Authors', resource: 'search?query=' },
      Category: { title: 'Categories', resource: 'search?categories=' },
      About: { title: 'About', resource: '' }
    };
    return indexes[index];
  }

  function buildLink(suggestion) {
    const indexName = suggestion.indexName;
    const resource = findValuesByIndex(indexName).resource;
    const toSearchPage = ['Author', 'Category'];
    const redirectFromSlug = ['Content', 'About'];

    if (toSearchPage.includes(indexName)) {
      return `/${encodeURI(`${resource}${suggestion.name}`)}`
    } else if (redirectFromSlug.includes(indexName)) {
      return `/${resource}${suggestion.slug}`
    } else {
      return `/${resource}${suggestion.friendly_id}`
    }
  }

  function buildTemplate(indexName) {
    const algoliaIndex = client.initIndex(indexName);
    const indexData = findValuesByIndex(indexName);
    return {
      source: autocomplete.sources.hits(algoliaIndex, { hitsPerPage: 3 }),
      displayKey: 'name',
      indexName: indexName,
      debounce: 350,
      templates: {
        header: `<div class="algoliasearch-category">${indexData.title}</div>`,
        suggestion: function (suggestion) {
          suggestion.indexName = indexName;
          return `<span class="dynamic-country-selector">${suggestion._highlightResult.name.value}</span>`;
        }
      }
    }
  }

  autocomplete('#search-page-query', {}, search).on('autocomplete:selected', function (e, suggestion, _dataset, _selectionMethod) {
    window.location = buildLink(suggestion);
  });

  $('.search-container').click((e) => {
    e.preventDefault();
    $('.searching-modal').addClass('open');
    $('body').addClass('body-searching-modal-open');

    setTimeout(() => {
      $('#search-page-query').focus();
    }, 300)
  });

  $('.searching-modal .fa-times').click((e) => {
    e.preventDefault();
    $('.searching-modal').removeClass('open');
    $('body').removeClass('body-searching-modal-open');
  });

  $('#search-page-query').on('keyup',function(){
    if($(this).val() == '') {
      $('#search-content-form').removeClass('search-page-query-active');
    } else {
      $('#search-content-form').addClass('search-page-query-active');
    }
  });

  $(document).keyup(function(e) {
    if ((e.keyCode === 27) && ($('.searching-modal').hasClass('open'))) {
      $('.searching-modal').removeClass('open');
    }
  });
});
