
/*************************** Obituary Page **************************************/

var previously_first_col_number = 1;

$('.previously-add-btn').click(function (e) {
  e.preventDefault();
  previously_first_col_number += 2;
  add_new_relation(previously_first_col_number);
});


function add_new_relation(first_col_number) {
  var second_col_number = first_col_number + 1;

  var relation_column = '<div class="col-span-2 lg:col-span-1 mx-[15px] my-[20px]">';
  relation_column += '<div class="grid grid-cols-12 ">'
  relation_column += '<div class="col-span-1 my-[20px]"><b class="font-sans-pro semi-bold text-xl text-s-dark-red">' + first_col_number + '</b></div>';

  relation_column += '<div class="col-span-11">'
  relation_column += '<div class="mt-[20px]">';
  relation_column += '<label for="obituary_deceased_relationships__name" class="my-[10px] block">Full name</label>';
  relation_column += '<input type="text" name="obituary[deceased_relationships][][name]" id="obituary_deceased_relationships__name" value="" class="form-control previously-deceased-input">';
  relation_column += '</div>';
  relation_column += '<div class="mt-[20px]">';
  relation_column += '<label for="obituary_deceased_relationships__relationship" class="my-[10px] block">Relationship to deceased</label>';
  relation_column += '<input type="text" name="obituary[deceased_relationships][][relationship]" id="obituary_deceased_relationships__relationship" value="" class="form-control previously-deceased-input">';
  relation_column += '</div>';
  relation_column += '<div class="mt-[20px]">';
  relation_column += '<label for="obituary_deceased_relationships__class_year" class="my-[10px] block">Stanford class year (if applicable)</label>';
  relation_column += '<input type="text" name="obituary[deceased_relationships][][class_year]" id="obituary_deceased_relationships__class_year" value="" class="form-control previously-deceased-input">';
  relation_column += '</div>';
  relation_column += '</div></div></div>';
  relation_column += '<div class="col-span-2 lg:col-span-1 mx-[15px] my-[20px]">';
  relation_column += '<div class="grid grid-cols-12 ">'
  relation_column += '<div class="col-span-1 my-[20px]"><b class="font-sans-pro semi-bold text-xl text-s-dark-red">' + second_col_number + '</b></div>';

  relation_column += '<div class="col-span-11 ">'
  relation_column += '<div class="mt-[20px]">';
  relation_column += '<label for="obituary_deceased_relationships__name" class="my-[10px] block">Full name</label>';
  relation_column += '<input type="text" name="obituary[deceased_relationships][][name]" id="obituary_deceased_relationships__name" value="" class="form-control previously-deceased-input">';
  relation_column += '</div>';
  relation_column += '<div class="mt-[20px]">';
  relation_column += '<label for="obituary_deceased_relationships__relationship" class="my-[10px] block">Relationship to deceased</label>';
  relation_column += '<input type="text" name="obituary[deceased_relationships][][relationship]" id="obituary_deceased_relationships__relationship" value="" class="form-control previously-deceased-input">';
  relation_column += '</div>';
  relation_column += '<div class="mt-[20px]">';
  relation_column += '<label for="obituary_deceased_relationships__class_year" class="my-[10px] block">Stanford class year (if applicable)</label>';
  relation_column += '<input type="text" name="obituary[deceased_relationships][][class_year]" id="obituary_deceased_relationships__class_year" value="" class="form-control previously-deceased-input">';
  relation_column += '</div>';
  relation_column += '</div></div></div>';

  var add_form = '';
  add_form += relation_column;

  $('.previously-add-btn-container').before(add_form);
}



var survivos_first_col_number = 1;

$('.survivos-add-btn').click(function (e) {
  e.preventDefault();
  survivos_first_col_number += 2;
  add_new_survivor(survivos_first_col_number);
});


function add_new_survivor(first_col_number) {
  var second_col_number = first_col_number + 1;

  var survivor_column = '<div class="col-span-2 lg:col-span-1 mx-[15px] my-[20px]">';

  survivor_column += '<div class="grid grid-cols-12 ">'
  survivor_column += '<div class="col-span-1 my-[20px]"><b class="font-sans-pro semi-bold text-xl text-s-dark-red">' + first_col_number + '</b></div>';
  survivor_column += '<div class="col-span-11">'
  survivor_column += '<div class="mt-[20px]">';
  survivor_column += '<label for="obituary_survivors__name" class="my-[10px] block">Full Name</label>';
  survivor_column += '<input type="text" name="obituary[survivors][][name]" id="obituary_survivors__name" value="" class="form-control survivor-input">';
  survivor_column += '</div>';
  survivor_column += '<div class="mt-[20px]">';
  survivor_column += '<label for="obituary_survivors__relationship" class="my-[10px] block">Relationship to deceased</label>';
  survivor_column += '<input type="text" name="obituary[survivors][][relationship]" id="obituary_survivors__relationship" value="" class="form-control survivor-input">';
  survivor_column += '</div>';
  survivor_column += '<div class="mt-[20px]">';
  survivor_column += '<label for="obituary_survivors__class_year" class="my-[10px] block">Stanford class year (if applicable)</label>';
  survivor_column += '<input type="text" name="obituary[survivors][][class_year]" id="obituary_survivors__class_year" value="" class="form-control survivor-input">';
  survivor_column += '</div>';
  survivor_column += '</div></div></div>';

  survivor_column += '<div class="col-span-2 lg:col-span-1 mx-[15px] my-[20px]">';
  survivor_column += '<div class="grid grid-cols-12 ">'
  survivor_column += '<div class="col-span-1 my-[20px]"><b class="font-sans-pro semi-bold text-xl text-s-dark-red">' + second_col_number + '</b></div>';
  survivor_column += '<div class="col-span-11 ">'
  survivor_column += '<div class="mt-[20px]">';
  survivor_column += '<label for="obituary_survivors__name" class="my-[10px] block">Full name</label>';
  survivor_column += '<input type="text" name="obituary[survivors][][name]" id="obituary_survivors__name" value="" class="form-control previously-deceased-input">';
  survivor_column += '</div>';
  survivor_column += '<div class="mt-[20px]">';
  survivor_column += '<label for="obituary_survivors__relationship" class="my-[10px] block">Relationship to deceased</label>';
  survivor_column += '<input type="text" name="obituary[survivors][][relationship]" id="obituary_survivors__relationship" value="" class="form-control previously-deceased-input">';
  survivor_column += '</div>';
  survivor_column += '<div class="mt-[20px]">';
  survivor_column += '<label for="obituary_survivors__class_year" class="my-[10px] block">Stanford class year (if applicable)</label>';
  survivor_column += '<input type="text" name="obituary[survivors][][class_year]" id="obituary_survivors__class_year" value="" class="form-control previously-deceased-input">';
  survivor_column += '</div>';
  survivor_column += '</div></div></div>';

  var add_form = '';
  add_form += survivor_column;

  $('.survivos-add-btn-container').before(add_form);
}

$('.previously-deceased-checkbox').change(function () {
  if ($(this).is(':checked')) {
    $('#obituary_deceased_relationships_name_1').prop('required', false);
    $('.previously-deceased-hiding-fields').slideUp(400);
  } else {
    $('#obituary_deceased_relationships_name_1').prop('required', true);
    $('.previously-deceased-hiding-fields').slideDown(400);
  }
});

$('.survivors-checkbox').change(function () {
  if ($(this).is(':checked')) {
    $('#survivor_name_1').prop('required', false);
    $('.survivors-hiding-fields').slideUp(400);
  } else {
    $('#survivor_name_1').prop('required', true);
    $('.survivors-hiding-fields').slideDown(400);
  }
});

document.addEventListener('invalid', function (e) {
  var delay = 0;
  var offset = 130;
  $(e.target).addClass("invalid");
  $('html, body').animate({scrollTop: $($(".invalid")[0]).offset().top - offset }, delay);
}, true);

document.addEventListener('change', function (e) {
  $(e.target).removeClass("invalid")
}, true);
