/********** Search Page  Select box ****************************/

$( document ).ready(function() {

  $("select.custom-select").each(function () {
    if ($('.custom-select-wrapper').length === 0) {
      var template = '<div class="custom-select border border-s-grey w-[250px] rounded-[3px] py-[15px] px-[10px]">';
      template += '<span class="custom-select-trigger text-s-light-black cursor-pointer tracking-wider text-left font-sans-pro text-xl relative block">' + $(this).attr("placeholder") + '</span>';
      template += '<div class="custom-options">';
      $(this).find("option").each(function () {
        template += '<span class="custom-option ' + $(this).attr("class") + '" data-value="' + $(this).attr("value") + '">' + $(this).html() + '</span>';
      });
      template += '</div></div>';

      $(this).wrap('<div class="custom-select-wrapper relative select-none inline-block"></div>');
      $(this).hide();
      $(this).after(template);
    }
  });

  $(".custom-select-trigger").on("click", function (e) {
    $('html').one('click', function () {
      $(".custom-select").removeClass("opened");
    });
    $(this).parents(".custom-select").toggleClass("opened");
    e.stopPropagation();
  });

  $(".issues-sort .custom-option").on("click", function () {
    var pagelink = window.location.origin + window.location.pathname;
    if ($(this).data("value")) {
      window.location.href = pagelink + '?year=' + $(this).data("value");
    } else {
      window.location.href = pagelink;
    }
  });

  $(".custom-option:first-of-type").hover(function () {
    $(this).parents(".custom-options").addClass("option-hover");
  }, function () {
    $(this).parents(".custom-options").removeClass("option-hover");
  });

  $(".search-sort .custom-option").on("click", function () {
    var pagelink = window.location.origin + window.location.pathname;
    window.location.href = pagelink + window.location.search + '&sort=' + $(this).data("value");
  });
});
